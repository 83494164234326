import { Nullable } from '@/core/domain/type/types'

export interface ISource {
  id: number;
  guid: string;
  name: string;
  parent_guid: Nullable<string>;
  type: string;
  row_order: number;
  is_leaf: boolean;
}

export default class SourceDTO {
  public id: number;
  public guid: string;
  public name: string;
  public parent_guid: Nullable<string>;
  public type: string;
  public row_order: number;
  public is_leaf: boolean;

  constructor (data: ISource) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.parent_guid = data.parent_guid
    this.type = data.type
    this.row_order = data.row_order
    this.is_leaf = data.is_leaf
  }
}
